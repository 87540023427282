<template>
  <ValidationObserver
    ref="form"
    v-slot="{ passes }"
    tag="section"
    class="pt-3"
  >
    <van-cell-group
      title="基本信息"
      class="mt-2"
    >
      <ValidationProvider
        v-slot="{ errors }"
        vid="status"
        name="状态"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="form.status"
          required
          readonly
          clearable
          placeholder="请选择状态"
          autocomplete="off"
          label="状态"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showStatusPicker = true"
        />
      </ValidationProvider>
    </van-cell-group>

    <div class="w-full px-4 mt-6">
      <van-button
        type="danger"
        block
        round
        :loading="processing"
        :disabled="article.internal"
        loading-text="发送中..."
        @click="passes(onSubmit)"
      >
        保存修改
      </van-button>
      <div
        v-if="article.internal"
        class="w-full py-2 text-xs text-center text-gray-400"
      >
        内部资料无法发布
      </div>
    </div>
    <van-popup
      v-model="showStatusPicker"
      round
      get-container="body"
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :default-index="statusDefaultIndex"
        :columns="Object.keys(articleStatus)"
        @cancel="showStatusPicker = false"
        @confirm="onStatusConfirm"
      />
    </van-popup>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'WorkspaceCmsManage',
  props: {
    collection: {
      type: String,
      default: 'fire'
    },
    article: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      showStatusPicker: false,
      form: {
        status: null,
        responsible: null
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo']),
    ...mapState('Common/Config', ['articleStatus']),
    statusDefaultIndex() {
      if (!this.form.status || !this.articleStatus) {
        return 0
      }
      let pos = Object.keys(this.articleStatus).indexOf(this.form.status)
      return pos >= 0 ? pos : 0
    },
    ...mapState('Common/Config', ['cmsCollections']),
    collectionName() {
      if(!this.collection) {
        return ''
      }
      return this.cmsCollections?.[this.collection] ?? ''
    }
  },
  watch: {
    article: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        let { status } = this.$clone(val)
        this.form = { status }
      }
    }
  },
  mounted() {},
  methods: {
    onStatusConfirm(value) {
      this.form.status = value
      this.showStatusPicker = false
    },
    onSubmit() {
      this.processing = true
      const data = {
        $url: 'workspacePost',
        collection: this.collection,
        userInfo: this.userInfo,
        id: this.$route.params.id,
        form: this.form
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'cms',
          data
        })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log(res)
          let { result } = res
          if (result.code.toString().indexOf('2') == 0) {
            this.$dialog
              .confirm({
                title: '保存成功',
                message: `${this.collectionName}的信息修改成功！`,
                theme: 'round-button',
                cancelButtonText: '留在当前页',
                confirmButtonText: '返回列表'
              })
              .then(() => {
                this.$router.go(-1)
              })
          } else {
            if (result.code == 401) {
              this.$notify({
                type: 'danger',
                message: `只有「发言人」有权限修改${this.collectionName}状态`
              })
            } else {
              this.$notify({
                type: 'danger',
                message: result.error
              })
            }
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '发送失败，由于微信服务异常，暂时无法发送'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
