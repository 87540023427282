<template>
  <section class="min-h-screen">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <van-tabs
        v-model="activeTabName"
        sticky
        animated
        class="min-h-screen"
      >
        <van-tab
          title="专题内容预览"
          name="basic"
          class="bg-gray-100 tab-content-container pt-2"
        >
          <Basic
            :article="article"
            module="workspace"
          />
        </van-tab>
        <van-tab
          title="管理操作"
          name="manage"
          class="bg-gray-100 tab-content-container"
        >
          <Manage
            :article="article"
            :collection="collection"
          />
        </van-tab>
      </van-tabs>

      <van-empty
        v-if="!article"
        description="无法加专题内容信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/articles/basic'
import Manage from './components/manage'

import share from '@/mixins/share'
export default {
  name: 'WorkspaceCmsEdit',
  components: {
    Basic,
    Manage
  },
  mixins: [share],
  data() {
    return {
      collection: null,
      loading: true,
      processing: false,
      article: null,
      activeTabName: 'basic'
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb', 'db', '_']),
    ...mapState('Common/Config', ['articleStatus']),
  },
  async mounted() {
    this.collection = this.$route.params.collection
    await Promise.all([
      this.fetch(),
      this.$store.dispatch('Workspace/Committee/fetch'),
      this.prepareWx()
    ])
    let options = {
      title: this.article?.title,
      desc: this.article?.subtitle,
    }
    if(this.article?.image) {
      options.imgUrl = this.article.image
    }
    this.updateShareData(options)

    if(typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        let {data} = await this.db
          .collection(this.collection)
          .doc(this.$route.params.id)
          .get()
        // eslint-disable-next-line no-console
        console.warn('获取到相关专题内容', data)

        if (!data) {
          this.$notify({ type: 'danger', message: '找不到相关内容' })
        } else {
          let article = data
          this.$set(this, 'article', article)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取专题内容', err)
        this.article = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
.tab-content-container {
  min-height: calc(100vh - 44px);
}
</style>
